.card
{
    margin: 10px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;   
    width: 450px;
    /*min-height: 370px;*/
    min-height: 200px;
    transition: all 0.7s; 
    padding: 15px 10px 15px 10px;
    /*border:  2px red solid;*/
    border-radius: 20px;
    animation: ani 2.5s forwards;
    /*background-color:  rgba(231, 14, 14, 0.692);*/
    box-shadow: 0px 5px 15px rgba(128, 8, 8, 0.425);  

}
.red
{
    background-color:  rgba(231, 14, 14, 0.692);
}
.orange
{
    background-color:  rgba(255, 166, 0, 0.856);
}
.green
{
    background-color:  rgb(8, 214, 8)
}
.gray
{
    background-color: rgba(128, 128, 128, 0.623)
}
.test
{
    opacity: 0;
    transition: opacity .7s;
}
.card:hover .test
{    opacity: 1;
    transition: opacity 1.2s;

}

@media screen and (max-width:600px) {
    .card
    { 
        width:350px;
    }
  }
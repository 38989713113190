header
{    
    /*flex-direction: row;*/

    display: flex;
    min-height: 100px;
    background: rgba(0, 0, 0, 0.37);
    padding: 5px;
    justify-content: space-around;

}
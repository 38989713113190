.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /*color: white;*/
  /*background: linear-gradient(20deg, #000000b4 0%, #686868 95%), #3f3131;*/

}

button {
  width: 200px;
  max-height: 50px;
  position: relative;
  overflow: hidden;  
  /*min-height: 40%;   */
  /*min-width: 20%;*/
  text-align: center;
  text-decoration:none;
  padding: 10px 15px;
  margin: 5px 5%;
  flex-grow: 1;
  background: rgba(190, 187, 187, 0.925);
  transition: all 0.7s;
  border-radius: 10px;
  font-size: 15px;
  cursor: pointer;
  box-shadow: 0px 5px 15px rgba(48, 47, 47, 0.363);  
  /*animation-delay: 10s;*/
}
.blockbutton
{
  display: flex;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
    /*width: 70%;*/
}
input
{
  width: 430px;
  /*height: 25px;*/
  /*line-height: 25px;*/
  font-size: 20px;
  margin: 10px;

}
select
{
  width: 430px;
  /*height: 25px;*/
  font-size: 20px;
  margin: 10px;
}
label
{
  margin: 10px;

}
@media screen and (max-width:600px) {
  input,select
  { 
      width:300px;
  }

}

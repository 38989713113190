main
{
    display: flex;
    flex-direction: column;  
    flex-grow: 1;
    align-items: center;
}
.list
{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    /*flex-direction: column;  */
    flex-wrap: wrap;
    justify-content: center;
    background: white;

}